import {useMutation, useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {createSelector} from 'reselect'
import {BaseResponse, Pagination, Response, ResponseFieldError, ResponseList} from 'src/app/models/api.types'
import {EventListModel, EventParams, SessionListModel, SessionParams, ValidateTicketModel, ValidateTicketParams} from '../models/ScanTicket.model'
import { getEventList, getSessionList, URL_EVENT_LIST, URL_SESSION_LIST, URL_VALIDATE_TICKET, validateTicket } from '../services/ScanTicket.service'

const selectEventList = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<EventListModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<EventListModel[]>>) => data.response_output?.list
);

const selectSessionList = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<SessionListModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<SessionListModel[]>>) => data.response_output?.list
);

export function useGetEventList(params: EventParams) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<EventListModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<EventListModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_EVENT_LIST, params],
    queryFn: (_) => getEventList(params, _.signal),
    enabled: !!params.directory_ids,
    select: selectEventList,
  });
}

export function useGetSessionList(params: SessionParams) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<SessionListModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<SessionListModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_SESSION_LIST, params],
    queryFn: (_) => getSessionList(params, _.signal),
    enabled: !!params.directory_id,
    select: selectSessionList,
  });
}

export function useValidateTicket() {
  return useMutation<
    AxiosResponse<BaseResponse<Response<ValidateTicketModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    ValidateTicketParams
  >({
    mutationFn: (params: ValidateTicketParams) => validateTicket(params),
    onSuccess: (data) => {
      console.log('Ticket validated successfully:', data);
    },
    onError: (error) => {
      console.error('Ticket validation failed:', error);
    },
  });
}
