import { ScreenProps } from "src/app/models/screen-prop";

const ScanTicketScreens: ScreenProps = {
  SCAN_TICKET: {
    PATH: '/scan-ticket',
    TITLE: 'Ticket Validation',
    TITLE_ALT: 'Ticket Validation',
    PERMISSION: 'gate_keeper.read',
  },
};

export default ScanTicketScreens;
