import {
  useEffect,
  useMemo,
  useState
} from "react";
import {
  useHistory,
  useParams
} from "react-router-dom";

import {
  usePermissions
} from "src/app/hooks/permissions-hook";
import {
  PageTabsData,
  PageTabsProps
} from "./PageTabs.types";

export function usePageTabs({
  onChangeTab,
  baseRoute,
  data
}: PageTabsProps) {

  const permission = usePermissions();
  const history = useHistory();
  const params = useParams<{ path: string }>();

  const tabs = useMemo(
    () => data.filter((_) => permission.hasAccess(_.permission)),
    []
  );

  const getTabIndex = () => {
    const _tabIndex = tabs.findIndex((_) => _.path === params.path);
    return Math.max(0, _tabIndex);
  };

  const [tabIndex, setTabIndex] = useState<number>(() => getTabIndex());

  useEffect(() => {
    if (!params.path) {
      setPage(tabs[0]);
    } else {
      setTabIndex(getTabIndex());
    }
  }, [params.path]);

  const setPage = (item: PageTabsData, index = 0) => {
    const route = baseRoute.replace(':path', item.path);
    let routeParams = item?.customPath ? `${route}?${item?.customPath}` : `${route}?page=1&limit=10`;
    history.replace(routeParams);
    onChangeTab?.(item, index);
  };

  return {
    activeTab: tabs[tabIndex],
    tabIndex,
    setPage,
    tabs
  };
}
