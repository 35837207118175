import React from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'src/app/routers/ProtectedRoute'
import ScanTicketScreen from './ScanTicketScreen'
import ScanTicketPage from './page/ScanTicket.page'

const ScanTicketRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={ScanTicketScreen.SCAN_TICKET.PATH}
        screen={ScanTicketScreen.SCAN_TICKET}
        component={ScanTicketPage}
        exact
      />
    </Switch>
  )
}

export default ScanTicketRoutes;
