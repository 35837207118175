import TimePicker from "react-multi-date-picker/plugins/time_picker";

import GenericDatePicker, {
  DateObject,
  DatePickerProps as GenericDatePickerProps
} from "react-multi-date-picker";
import {
  twMerge
} from "tailwind-merge";
import {
  FocusEvent,
  forwardRef,
  useEffect,
  useRef,
  useState
} from "react";

import {
  TextField
} from "../TextField/TextField.page";
import {
  DatePickerProps
} from './DatePicker.types';
import {
  CalendarIcon,
  CaretIcon
} from '../../Icons';
import {
  parseInputValue
} from "./DatePicker.utils";
import {
  safeArray
} from "src/app/utils/array-utils";
import {
  DatePickerVariants,
  Theme
} from "./DatePicker.theme";
import dayjs from "dayjs";

const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

function _DatePicker(
  {
    calendarClassName,
    placeholder,
    helperText,
    inputProps,
    className,
    required,
    hideTime,
    onChange,
    onClose,
    format,
    onOpen,
    error,
    label,
    rule,
    onlyMonthPicker,
    onlyYearPicker,
    showCalendarIcon = true,
    variant,
    fitToContent,
    ...props
  }: DatePickerProps & GenericDatePickerProps,
  _: any
) {

  const [portalTarget, setPortalTarget] = useState<HTMLElement>();
  const [isFocus, setFocus] = useState(false);

  const datePickerRef = useRef<any>();

  useEffect(() => {
    if (isFocus) {
      const portalEl = document.createElement('div');
      portalEl.style.zIndex = '999999';
      portalEl.onclick = (_) => _.stopPropagation();
      document.body.appendChild(portalEl);
      setPortalTarget(portalEl);
      return () => {
        document.body.removeChild(portalEl);
      }
    }
  }, [isFocus]);

  const handleChange = (value: DateObject | DateObject[] | null) => {
    onChange?.(value);
    if (props.range && safeArray(value).length > 1) {
      setTimeout(() => datePickerRef.current?.closeCalendar(), 250);
    }
  };


  return (
    <GenericDatePicker
      containerClassName={twMerge([
        className,
        fitToContent && onlyMonthPicker && Theme.fitToContent.onlyMonthPicker,
        fitToContent && onlyYearPicker && Theme.fitToContent.onlyYearPicker,
      ])}
      monthYearSeparator=" "
      containerStyle={{ width: "100%" }}
      showOtherDays={true}
      className={twMerge("custom-calendar", calendarClassName)}
      weekDays={weekDays}
      onlyMonthPicker={onlyMonthPicker}
      onlyYearPicker={onlyYearPicker}
      format="YYYY/MM/DD HH:mm:ss"
      portalTarget={portalTarget}
      portal={true}
      months={months}
      onChange={handleChange}
      ref={datePickerRef}
      onClose={() => {
        setFocus(false);
        onClose?.();
      }}
      onOpen={() => {
        setFocus(true);
        onOpen?.();
      }}
      plugins={!hideTime ? [
        <TimePicker
          hideSeconds={true}
          position="bottom"
          onFocus={(_: FocusEvent<HTMLInputElement>) => _.target?.select()}
          key="time-picker"
        />
      ] : undefined}
      render={(value, openCalendar) => {
        const _value = parseInputValue(value, { ...props, format });
       
        const startIcon = showCalendarIcon ? CalendarIcon : undefined
        return (
          <TextField
            classes={{
              endIcon: twMerge("transition-transform", isFocus && "-rotate-180"),
              container: twMerge([
                Theme.input,
                fitToContent && onlyMonthPicker && Theme.fitToContent.onlyMonthPicker,
                fitToContent && onlyYearPicker && Theme.fitToContent.onlyYearPicker,
                variant && DatePickerVariants[variant]?.container

              ]),
              input: twMerge([
                variant && DatePickerVariants[variant]?.input
              ],

              ),
            }}
            startIcon={startIcon}
            onFocus={() => openCalendar()}
            readOnly={true}
            endIcon={CaretIcon}
            {...{
              ...inputProps,
              value: _value ?? "",
              placeholder,
              helperText,
              required,
              error,
              label,
              rule,
            }}
          />
        )
      }}
      {...props}
    />
  );
}

const DatePicker = forwardRef(_DatePicker);

DatePicker.defaultProps = {
  dateSeparator: ' - ',
  hideTime: true,
} as DatePickerProps;

export { DatePicker }
