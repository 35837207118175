import SwipeableViews from "react-swipeable-views";

import { createElement } from "react";
import { useParams } from "react-router-dom";

import { PageTitle } from "../../Layout/header/HeaderTitle";
import { UnderlineTabs } from "../UnderlineTabs/UnderlineTabs.page";
import { usePageTabs } from "./PageTabs.hooks";
import { PageTabsData, PageTabsProps } from "./PageTabs.types";

export function PageTabs({
  unmountOnHidden = true,
  title,
  ...props
}: PageTabsProps) {

  const params = useParams<{ path: string }>();
  const pageTabs = usePageTabs(props);

  const renderItem = (item: PageTabsData, index: number) => {
    if (!unmountOnHidden || index === pageTabs.tabIndex) {
      return createElement(item.component, { ...item, title: item.label, key: item.path });
    }
    return <div key={item.path} />;
  };

  return (
    <>
      {!!title && (
        <PageTitle description={pageTabs?.activeTab?.label}>
          {title}
        </PageTitle>
      )}
      <UnderlineTabs
        className="mb-5"
        onChange={pageTabs.setPage}
        index={pageTabs.tabIndex}
        items={pageTabs.tabs}
      />
      {(!!params.path) && (
        <SwipeableViews disableLazyLoading={false} index={pageTabs.tabIndex}>
          {pageTabs.tabs.map(renderItem)}
        </SwipeableViews>
      )}
    </>
  );
}

