import dayjs from "dayjs";

import { UseFormReturn } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { DateObject } from "react-multi-date-picker";

interface UseSafeFormDateRangeParams {
  startDateKey: string;
  endDateKey: string;
  form: UseFormReturn<any, any, undefined>;
  type?: 'date' | 'time',
  maxDiff?: number
}

export function getDiff(startDate?: string, endDate?: string) {
  if (!!startDate && !!endDate) {
    const dateStart = dayjs(startDate);
    const dateEnd = dayjs(endDate);
    const diff = dateEnd.diff(dateStart, 'days');
    return diff;
  }
  return 0;
}

export function isJumpStartDate(startDate?: string, endDate?: string, maxDiff: number = 1) {
  if (startDate && endDate) {
    const dateStart = dayjs(startDate);
    const dateEnd = dayjs(endDate);

    // Only return true when startDate is after endDate.
    if (dateStart.isAfter(dateEnd)) {
      return true;
    }
  }
  return false;
}

export function useSafeFormDateRange({
  startDateKey,
  endDateKey,
  form,
  maxDiff
}: UseSafeFormDateRangeParams) {

  const startDate = form.watch(startDateKey);
  const endDate = form.watch(endDateKey);

  useEffect(() => {
    if (isJumpStartDate(startDate, endDate, maxDiff)) {
      form.setValue(endDateKey, '');
    }
  }, [startDate]);

  const value = useMemo(() => {
    return {
      minStartDate: new DateObject().set('hour', 0).set('minute', 0).set('second', 0),
      minEndDate: new DateObject(startDate).set('hour', 23).set('minute', 59).set('second', 59),
      diff: getDiff(startDate, endDate)
    }
  }, [
    startDate,
    endDate
  ]);

  return value;
}
