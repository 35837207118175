import {DateObject} from 'react-multi-date-picker'

import {useFilterMenuContext} from 'src/app/components'
import {useUrlSearchParams} from 'src/app/hooks'
import {months, rewardTypes, statuses} from './FilterMenuContent.templates'
import {FilterMenuContentProps} from './FilterMenuContent.types'
import {convertPeriod} from './FilterMenuContent.utils'

export function useFilterMenuContentHook() {

  const [searchParams] = useUrlSearchParams<'reward_types' | 'status' | 'months' | 'period' | 'directory_ids' | 'start_date'| 'reward_id'>()
  const defaultFilter = {
    directory_ids: searchParams.directory_ids,
    reward_types: searchParams.reward_types,
    status: searchParams.status,
    months: searchParams.months,
    period: searchParams.period,
    start_date : searchParams.start_date,
    reward_id: searchParams.reward_id
  }

  const filterContext = useFilterMenuContext<typeof searchParams>({defaultValues: defaultFilter})

  const getStatusMaster = (params: FilterMenuContentProps) => {
    return statuses.filter((item) => {
      if (item.value === 'INACTIVE') {
        return params.withStatusInactive
      }
      if (item.value === 'PENDING') {
        return params.withStatusPending
      }
      if (item.value === 'ACTIVE') {
        return params.withStatusActive
      }
      return true
    })
  }

  const handleChangePeriod = (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({period: convertPeriod(value)})
  }

  const handleChangeStartTime= (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({start_date: convertPeriod(value)})
  }

  const handleChangeMonth = (value: DateObject | null) => {
    filterContext.updateDraft({months: value?.toFirstOfMonth().format('YYYY-MM-DD')})
  }

  const handleChangeEventName = (event : string) =>{
    filterContext.updateDraft({reward_id: event})
  }

  return ({
    filterContext,
    action: {
      handleChangePeriod,
      handleChangeMonth,
      handleChangeStartTime,
      handleChangeEventName
    },
    master: {
      getStatusMaster,
      rewardTypes,
      statuses,
      months,
    },
  })
}
