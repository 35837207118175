import {
  useLocation,
  useParams,
} from 'react-router-dom'

import {
  DatePicker,
  Label,
  MenuContent,
  Radio,
  Stack,
} from 'src/app/components'
import {
  getPeriodValue,
} from './FilterMenuContent.utils'
import {
  useFilterMenuContentHook,
} from './FilterMenuContent.hooks'
import {
  FilterMenuContentProps,
} from './FilterMenuContent.types'
import MenuContentMall from 'src/app/components/Feedback/MenuContent/MenuContent.mall'
import { SelectRewards } from '../../Inputs/SelectAsync/SelectReward.page'
import { useApiParams } from 'src/app/hooks'
import { useEffect } from 'react'

function FilterMenuContent(props: FilterMenuContentProps) {

  const params = useParams<{path: string}>()
  const apiParams = useApiParams()
  const location = useLocation();
  const state = useFilterMenuContentHook()
  const statusMaster = state.master.getStatusMaster(props)

  const directoryIds = state.filterContext.draft?.directory_ids || '';
  const directoryIdsArray = directoryIds.split('.').filter(id => id);
  const renderRewardItem = (item: typeof state.master.rewardTypes[0]) => {
    return (
      <Radio
        label={item.label}
        {...state.filterContext.registerRadio({name: 'reward_types', value: item.value})}
        key={item.value}
      />
    )
  }

  const renderStatusItem = (item: typeof state.master.statuses[0]) => {
    const mappedLabel = mapStatusLabel(item.label);
    const mappedValue = mapStatusLabel(item.value);
    return (
      <Radio
        {...state.filterContext.registerRadio({name: 'status', value: mappedValue})}
        label={mappedLabel}
        key={item.value}
      />
    )
  }

  const mapStatusLabel = (status: string): string => {
    const statusMap: Record<string, string> = {
      Pending: 'Upcoming',
      Active: 'Ongoing',
      Inactive: 'Finish',
    };

    const statusKeyMap: Record<string, string> = {
      PENDING: 'UPCOMING',
      ACTIVE: 'ONGOING',
      INACTIVE: 'FINISH',
    };
  
    // Check if the status matches the event, apply mapping if true; else return as is
    if (extractEvent() === 'event') {
      return statusMap[status] || status;
    } else if (extractEvent() === 'session') {
      return statusMap[status] || statusKeyMap[status];
    }
    return status; // Return the original status if the condition is not met
  };

  // Extract the `event` part from the URL
  const extractEvent = () => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[pathSegments.length - 1]; // Get the last segment as `event`
  };

  const handleChangeEvent = (data: {id : string} | any)=>{
    state.action.handleChangeEventName(data?.id)
  }

  return (
    <MenuContent>
      {(props.withRewardType) && (
        <div>
          <Label>Reward Type</Label>
          <Stack direction="row">
            {state.master.rewardTypes.map(renderRewardItem)}
          </Stack>
        </div>
      )}
      {(params.path === 'birthday-gift') ? (
        <DatePicker
          placeholder="Select date"
          onChange={state.action.handleChangeMonth}
          format="MMMM YYYY"
          value={state.filterContext.draft?.months}
          label="Time"
          onlyMonthPicker
          range={false}
        />
      ) : (
        props.withPeriod && !props.isSession &&  (
          <DatePicker
            placeholder="Select date"
            onChange={state.action.handleChangePeriod}
            format="DD/MM/YYYY"
            value={getPeriodValue(state.filterContext.draft?.period)}
            range={true}
            label="Period"
          />
        )
      )}

      {(props.withMall) && <MenuContentMall filterContext={state.filterContext} />}
      {(props.withEvent) &&
        <SelectRewards
          className='flex-1'
          key={directoryIds.toString()}
          label='Event Name'
          placeholder='Select Event'
          isClearable
          selectKey={'id'}
          payload={{
            is_dropdown: true,
            limit: 100,
            reward_type: 'TICKET',
            'directory_ids[]': directoryIdsArray
           
          }}
          onChangeValue={handleChangeEvent}
        />
      }
      {(props.withPeriod && props.isSession) && 
          <DatePicker
          placeholder="Select date"
          onChange={state.action.handleChangeStartTime}
          format="DD/MM/YYYY"
          value={getPeriodValue(state.filterContext.draft?.period)}
          range={false}
          label="Period"
    />
      }
      {(props.withStatus) && (
        <div>
          <Label>Status</Label>
          <Stack direction="row">
            {statusMaster.map(renderStatusItem)}
          </Stack>
        </div>
      )}
    </MenuContent>
  )
}

FilterMenuContent.defaultProps = {
  withStatusInactive: true,
  withStatusPending: true,
  withStatusActive: true,
  withStatus: true,
  withMall: true
} as FilterMenuContentProps

export {FilterMenuContent}
