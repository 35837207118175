import {useForm} from 'react-hook-form'
import {MallModel} from 'src/app/modules/directory/models'
import {useEffect, useRef, useState} from 'react'
import {DialogRef} from 'src/app/components'
import {
  useGetEventList,
  useGetSessionList,
  useValidateTicket,
} from '../providers/ScanTicket.provider'
import {SessionListModel} from '../models/ScanTicket.model'
import dayjs from 'dayjs'

const defaultValues = {
  directory: {} as MallModel,
  event: {} as any,
  date: new Date(),
  session: {} as any,
  member_ticket_code: '',
}

export function useScanTicketHooks() {
  const form = useForm({defaultValues})
  const validateTicket = useValidateTicket()
  const loadingDialogRef = useRef<DialogRef>(null)
  const [triggerType, setTriggerType] = useState<'cam' | 'typing' | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isScannerVisible, setIsScannerVisible] = useState(false)

  const openLoadingDialog = () => {
    loadingDialogRef.current?.show()
  }
  const closeLoadingDialog = () => {
    loadingDialogRef.current?.hide()
  }
  const openScanner = () => {
    setTriggerType('cam')
    setIsScannerVisible(true)
  }
  const closeScanner = () => {
    setTriggerType(null)
    setIsScannerVisible(false)
  }
  const resultDialog = useRef<DialogRef>(null)
  const [resultDialogContent, setResultDialogContent] = useState<{
    type: 'success' | 'error'
    wording: string
    subwording?: string | null
  }>({
    type: 'success',
    wording: '',
  })
  const openResultDialog = () => {
    resultDialog.current?.show()
  }
  const closeResultDialog = () => {
    resultDialog.current?.hide()
    if (resultDialogContent.type === 'success' && triggerType === 'cam') {
      openScanner()
    }
  }

  const directory = form.watch('directory')
  const event = form.watch('event')
  const session = form.watch('session')
  const member_ticket_code = form.watch('member_ticket_code')

  useEffect(() => {
    const handler = setTimeout(() => {
      if (member_ticket_code.length >= 10) {
        handleSubmit()
      }
    }, 1000)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_ticket_code, form])

  const eventList = useGetEventList({
    directory_ids: !directory?.id ? undefined : [directory?.id],
    limit: 1000,
    page: 1,
    statuses: ['ACTIVE'],
    reward_type: 'TICKET',
    order: 'reward_name',
    sort: 'ASC',
  })

  const [sessions, setSessions] = useState<SessionListModel[] | undefined>([])

  const {data: sessionList, refetch: refetchGetSessionList} = useGetSessionList({
    directory_id: !directory?.id ? undefined : directory?.id,
    reward_id: !event?.id ? undefined : event?.id,
  })

  useEffect(() => {
    if (!directory || !event) {
      setSessions([])
      return
    }

    if (sessionList) {
      const updatedSessions = sessionList.content?.map((item) => ({
        ...item,
        is_selected: false,
        start_time: dayjs(item.start_date + ' ' + item.start_time).format('HH:mm'),
        end_time: dayjs(item.start_date + ' ' + item.end_time).format('HH:mm'),
      }))
      setSessions(updatedSessions)
    }
  }, [sessionList, directory, event])

  useEffect(() => {
    if (!event) {
      setSessions([])
    }
  }, [directory, event])

  const handleSelectedSession = (selectedSession: SessionListModel) => {
    setSessions((prevSessions) =>
      prevSessions?.map((session) => ({
        ...session,
        is_selected: session.id === selectedSession.id,
      }))
    )
  }

  const handleChangeMall = () => {
    form.setValue('event', null)
    form.setValue('session', null)
  }

  const handleSubmit = form.handleSubmit(
    async ({directory, event, session, member_ticket_code}) => {
      setIsLoading(true)
      openLoadingDialog()
      try {
        const payload = {
          directory_id: directory.id,
          reward_id: event.value,
          session_id: session,
          member_ticket_code: member_ticket_code,
        }
        const result = await validateTicket.mutateAsync(payload)
        if (result) {
          setResultDialogContent({type: 'success', wording: 'Success Validation'})
          form.setValue('member_ticket_code', '')
          openResultDialog()
        }
      } catch (error: any) {
        const wording =
          error?.response?.data?.response_schema?.response_message?.en || 'Internal Server Error'
        const subwording =
          wording === "Member's ticket invalid."
            ? 'Please check your ticket and the event schedule.'
            : null
        setResultDialogContent({type: 'error', wording, subwording})
        openResultDialog()
        form.setValue('member_ticket_code', '')
      } finally {
        closeLoadingDialog()
        setIsLoading(false)
        handleRefetchGetSessionList()
      }
    }
  )

  const handleSetSessionId = (values: Array<{rawValue: string}>) => {
    if (!values.length) return

    const rawValue = values[0].rawValue

    if (rawValue.length > 10) {
      form.setError('member_ticket_code', {
        type: 'maxLength',
        message: 'Maximum character limit is 10',
      })
      setIsScannerVisible(false)
      form.setValue('member_ticket_code', rawValue)
    } else {
      form.setValue('member_ticket_code', rawValue)
      form.clearErrors('member_ticket_code')
      setIsScannerVisible(false)
      handleSubmit()
    }
  }

  const handleRefetchGetSessionList = () => {
    refetchGetSessionList()
  }

  return {
    action: {
      handleSubmit,
      openScanner,
      closeScanner,
      handleSelectedSession,
      handleSetSessionId,
      openLoadingDialog,
      closeLoadingDialog,
      openResultDialog,
      closeResultDialog,
      handleChangeMall,
      handleRefetchGetSessionList,
    },
    state: {directory, event, session, member_ticket_code},
    form,
    isScannerVisible,
    isLoading,
    loadingDialogRef,
    resultDialog,
    resultDialogContent,
    options: {eventList, sessions},
  }
}
