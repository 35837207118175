import * as React from "react";

export function InfoPrimaryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 7.01V7m0 10v-7m9 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
      ></path>
    </svg>
  );
}
