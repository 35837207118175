// src/components/BarcodeScanner.tsx
import React, { ReactNode } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import GIcon from 'src/app/components/Libs/Icon/GIcon';


interface BarcodeScannerProps {
  isVisible: boolean;  // Controls the visibility of the barcode scanner modal
  onClose: () => void; // Function to close the barcode scanner
  onScan: (value: any) => void
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ isVisible, onClose, onScan }) => {
  if (!isVisible) return null; // Don't render the scanner if not visible

  return (
    <div className="fixed inset-0 z-[9999] h-screen bg-black bg-opacity-50 flex justify-center items-center">
      {/* Barcode Scanner Container */}
      <div onClick={onClose} className='rounded-full left-4 top-12 flex items-center justify-center h-[40px] w-[40px] bg-white absolute z-[99999]'>
        <GIcon icon='IconChevronLeftBlack'></GIcon>
      </div>
      <div className="w-full relative">
        <Scanner onScan={(result) => onScan(result)} />;
      </div>
    </div>
  );
};

export default BarcodeScanner;
