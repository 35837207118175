import { Ref, forwardRef } from "react";
import { Dialog, DialogContent, DialogRef } from "src/app/components";
import { LoadingIcon } from "src/app/components/Icons";

const DialogLoading = forwardRef((_: unknown, ref: Ref<DialogRef>) => {
  return (
    <Dialog
      closeOnBackdropClicked={false}
      size="xs"
      width='100px'
      ref={ref}
    >
      <DialogContent>
        <div className="flex flex-col gap-2 items-center justify-center">
          <LoadingIcon />
          <span>Checking ticket...</span>
        </div>
      </DialogContent>
    </Dialog>
  );
});

export { DialogLoading };
