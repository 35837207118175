import _isEmpty from "lodash/isEmpty";
import { Children, createElement, isValidElement } from "react";
import { useUrlSearchParams } from "src/app/hooks";
import { hasArray } from "src/app/utils/array-utils";
import { twMerge } from "tailwind-merge";
import { Typography } from "../../Display";
import { LoadingIcon } from "../../Icons";
import { Button } from "../../Inputs";
import { QueryStateProps } from "./QueryState.types";
import { QueryStateContent } from "./QueryStateContent.page";
import { Theme } from "./QueryStateContent.theme";

function QueryState<T>({
  emptyFilterProps,
  children,
  hasData,
  query,
  ...props
}: QueryStateProps<T>) {

  const [searchParams] = useUrlSearchParams<'search'>();

  const [
    dataEl,
    emptyEl
  ] = Children.toArray(children);

  if (hasData(query?.data)) {
    return <>{dataEl}</>;
  }

  if (query?.isFetching || query?.isLoading) {
    return (
      <QueryStateContent {...props}>
        <LoadingIcon />
        <Typography className={twMerge(Theme.description)}>
          Loading...
        </Typography>
      </QueryStateContent>
    );
  }

  if (query?.isError) {
    const errorMessage = query.error.response?.data.response_schema?.response_message?.en ?? query?.error.message;
    return (
      <QueryStateContent
        title="Error"
        description={errorMessage || "Interal server error"}
        {...props}
      >
        <Button onClick={() => query?.refetch()}>
          Reload
        </Button>
      </QueryStateContent>
    );
  }


  /**
   * Check filter applied by user (except limit and page)
   */
  const params: any = searchParams
  delete params.limit
  delete params.page

  if (!_isEmpty(params)) {
    return (
      <QueryStateContent
        title="Result Not found"
        description="Please enter new keywords or turn off some filters."
        {...{ ...emptyFilterProps, ...props }}
      />
    );
  }

  if (isValidElement(emptyEl)) {
    return createElement(emptyEl.type, {
      description: `Click 'Add' button to create new entry`,
      title: 'List is Empty',
      ...emptyEl.props,
      key: emptyEl.key
    });
  }

  if (!!emptyEl) {
    return <>{emptyEl}</>;
  }

  return <QueryStateContent description="No Data" {...props} />;
}

QueryState.defaultProps = {
  hasData: (_: { content: any[], id: any }) => hasArray(_?.content) || !!_?.id
} as QueryStateProps<any>;

export { QueryState };

