import { Children, createElement, isValidElement, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { useHistory } from "react-router-dom";
import { Stack } from "../../Layouts";
import { useDialogContext } from "./Dialog.context";
import { DialogActionProps } from "./DialogAction.types";

export function DialogAction<T>({
  onSuccessGoBack,
  className,
  children,
  mutation,
  loading,
  action,
  form,
  ...props
}: DialogActionProps<T>) {

  const [cancelEl, submitEl] = Children.toArray(children);

  const dialogContext = useDialogContext();
  const wasLoading = useRef<boolean>(false);
  const history = useHistory();

  const isLoading = mutation?.isLoading || loading;

  useEffect(() => {
    if (mutation?.isLoading) {
      wasLoading.current = true;
    }
  }, [mutation?.isLoading]);

  useEffect(() => {
    if (mutation?.isSuccess && wasLoading.current && dialogContext.visible) {
      dialogContext.hide();
      return () => {
        if (onSuccessGoBack) {
          setTimeout(() => history.goBack(), 350);
        }
      }
    }
  }, [
    dialogContext.visible,
    mutation?.isSuccess
  ]);

  return (
    <Stack
      direction="row"
      className={twMerge("ws:!gap-2 gap-4 p-4", className)}
      justify="end"
      {...props}
    >
      {isValidElement(cancelEl) && createElement(cancelEl.type, {
        children: "Cancel",
        disabled: isLoading,
        color: "primary",
        ...cancelEl.props,
        onClick: () => typeof cancelEl?.props?.onClick === "function" ? (cancelEl as any)?.props?.onClick?.() : dialogContext.hide(),
        className: twMerge("min-w-[128px]", cancelEl.props?.className),
        variant: "outlined"
      })}
      {isValidElement(submitEl) && createElement(submitEl.type, {
        children: (action === 'add') ? "Add" : "Save",
        onClick: form?.handleSubmit((_: any) => mutation?.mutate(_)),
        loading: isLoading,
        color: "primary",
        ...submitEl.props,
        className: twMerge("min-w-[128px]", submitEl.props?.className),
        variant: "contained"
      })}
    </Stack>
  );
}
