import axios from "axios";

import {BaseResponse, Pagination, Response} from 'src/app/models/api.types'
import { EventListModel, EventParams, SessionListModel, SessionParams, ValidateTicketModel, ValidateTicketParams } from "../models/ScanTicket.model";

export const URL_EVENT_LIST = '/v1/cms/rewards';
export const URL_SESSION_LIST = '/v1/cms/sessions/live-attendance';
export const URL_VALIDATE_TICKET = '/v1/cms/member-rewards/validate-ticket';

export async function getEventList(params: EventParams, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<EventListModel[]>>>(URL_EVENT_LIST, { params, signal });
}

export async function getSessionList(params: SessionParams, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<SessionListModel[]>>>(URL_SESSION_LIST, { params, signal });
}

export function validateTicket(params: ValidateTicketParams) {
  return axios.patch<BaseResponse<Response<ValidateTicketModel>>>(URL_VALIDATE_TICKET, params);
}
