import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, ActionButton, Select } from 'src/app/components';
import { ITicketPromotion } from 'src/app/modules/rewards/models/Rewards.model';

type Promotion = {
  id: string;
  detail: string;
  bank: string;
  promotionType: string;
  discount: string;
};

interface PromotionDetailProps {
  name: string;
  promotionOptions: ITicketPromotion[];
 
}

export function InputPromotionField ({name, promotionOptions,  ...props}: PromotionDetailProps)  {
  const { control, setValue, } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  const form = useFormContext();
  const selectedPromotions = useWatch({ control, name }) || [];

  
  const findTicket=(id: string): any=>{
    return fields.find(val => val.id == id)
  }

  useEffect(() => {
   
    if (form.getValues('ticket_promotions').length > 0 && promotionOptions.length > 0) {
      form.getValues('ticket_promotions')?.forEach((field: { id: string, ticket_promotion_id: string }, index: any) => {
       
        const selectedPromotion = promotionOptions.find(promo => promo.id === field.ticket_promotion_id);
        
        if (selectedPromotion) {
         
          setValue(`${name}[${index}].detail`, selectedPromotion.name);
          setValue(`${name}[${index}].bank`, selectedPromotion.payment_method_name);
          setValue(`${name}[${index}].promotionType`, selectedPromotion.payment_category_name);
          setValue(`${name}[${index}].discount`, selectedPromotion.discount);
          setValue(`${name}[${index}].disabled`, true);
          setValue(`${name}[${index}].id`, field.ticket_promotion_id);
         
        }
      });
    }
  }, [fields, promotionOptions, setValue]);

  const formatPromotionOption = React.useCallback((promotion: any): string | null => {
    if (!promotion) return null;
    return `${promotion.name? promotion.name: promotion.session_promotion_name} ${promotion.payment_method_name ? promotion.payment_method_name  : promotion.payment_category_name},
      ${promotion?.payment_category_name ? promotion?.payment_category_name : promotion?.payment_category_name}, Discount ${promotion.discount? promotion?.discount: promotion?.discount }%`;
  }, []);
  
  const getPromotionValue = React.useCallback((id: string) => {
    
    const selectedPromotion = promotionOptions.find(promo => promo.id === id);
    return selectedPromotion ? { label: formatPromotionOption(selectedPromotion), value: selectedPromotion.id } : null;
  }, [promotionOptions, formatPromotionOption]);

  const getFilteredOptions = (currentId: string) => {
    const selectedIds = selectedPromotions
      .map((item: any) => item?.ticket_promotion_id ? item?.ticket_promotion_id: item.id )
      .filter((id: string) => id !== currentId);

    return promotionOptions
      .filter(option => !selectedIds.includes(option.id))
      .map(option => ({
        label: formatPromotionOption(option),
        value: option.id,
      }));
  };



  const getDisabled = (fieldId: string) => {
    if (!selectedPromotions || selectedPromotions.length === 0) {
     
      return false;
    }
  
    const selectedPromotion = selectedPromotions.find(
      (item: any) => item?.ticket_promotion_id === fieldId
    );
  
    if (!selectedPromotion) {
     
      return false;
    }
  
    return selectedPromotion?.disabled ?? false;
  };
  

  return (
    <div className="border border-gray-300 rounded-lg p-4 col-span-2">
      <h2 className="text-lg font-semibold mb-4">Promotion Detail</h2>

      {fields.map((field, index) => (
  <div key={field.id} className="flex items-center gap-4 mb-4 w-full">
    <div className="flex-grow bg-gray-100 p-3 rounded-lg flex items-center w-full justify-between">
      {getDisabled(findTicket(field.id)?.ticket_promotion_id) ? (
        // Render InputTextField if disabled
        <Select
          placeholder="Select promotion"
          options={getFilteredOptions(
            findTicket(field.id)?.ticket_promotion_id
          )}
          isMulti={false}
          required={true}
          isDisabled={true}
          className="w-full"
          defaultValue={getPromotionValue(findTicket(field.id)?.ticket_promotion_id)}
          name={`${name}.${index}.id`}
          selectOption={(option: any) => ({
            label: option.value,
            value: option.value,
          })}
          onChangeValue={(selectedOption: any) => {
            const selectedPromotion = promotionOptions.find(
              (promo) => promo.id === selectedOption.value
            );
            if (selectedPromotion) {
              console.log('#selectedpromoOption', selectedOption.ticket_promotion_id)
              setValue(`${name}[${index}].id`, selectedOption.ticket_promotion_id);
              setValue(`${name}.${index}.detail`, selectedPromotion.name);
              setValue(`${name}.${index}.bank`, selectedPromotion.payment_method_name);
              setValue(`${name}.${index}.promotionType`, selectedPromotion.payment_category_name);
              setValue(`${name}.${index}.discount`, selectedPromotion.discount);
            }
          }}
        />
      ) : (
        // Render Select if not disabled
        <Select
          placeholder="Select promotion"
          options={getFilteredOptions(
            findTicket(field.id)?.ticket_promotion_id
          )}
          isMulti={false}
          required={true}
          className="w-full"
          defaultValue={getPromotionValue(findTicket(field.id)?.ticket_promotion_id)}
          name={`${name}.${index}.id`}
          selectOption={(option: any) => ({
            label: option.value,
            value: option.value,
          })}
          onChangeValue={(selectedOption: any) => {
            const selectedPromotion = promotionOptions.find(
              (promo) => promo.id === selectedOption.value
            );
            if (selectedPromotion) {
              setValue(`${name}[${index}].id`, selectedOption.value);
              setValue(`${name}.${index}.detail`, selectedPromotion.name);
              setValue(`${name}.${index}.bank`, selectedPromotion.payment_method_name);
              setValue(`${name}.${index}.promotionType`, selectedPromotion.payment_category_name);
              setValue(`${name}.${index}.discount`, selectedPromotion.discount);
            }
          }}
        />
      )}
      <ActionButton variant="delete" color="primary" disabled={getDisabled(findTicket(field.id)?.ticket_promotion_id)} onClick={() => remove(index)} />
    </div>
  </div>
))}


      {/* Add Promotion Button */}
      <div className="flex items-center justify-between p-4 mt-4 bg-gray-50 rounded-lg">
        <span>Want to add more promotion?</span>
        <Button
          variant="outlined"
          onClick={() =>
            append({
              id: Date.now().toString(),
              detail: '',
              bank: '',
              promotionType: '',
              discount: '',
            })
          }
        >
          Add promotion +
        </Button>
      </div>
    </div>
  );
};

export default InputPromotionField;
