import {Ref, forwardRef} from 'react'
import {Button, Dialog, DialogContent, DialogRef} from 'src/app/components'
import {DialogResultProps} from './DialogResult.types'

const DialogResult = forwardRef(
  ({type, wording, subwording, buttonText, onConfirm}: DialogResultProps, ref: Ref<DialogRef>) => {
    return (
      <Dialog closeOnBackdropClicked={false} size='xs' width='100px' ref={ref}>
        <DialogContent>
          <div className='flex flex-col items-center justify-between p-6'>
            <svg
              width={type === 'success' ? '49' : '61'}
              height={type === 'success' ? '48' : '60'}
              viewBox={type === 'success' ? '0 0 49 48' : '0 0 61 60'}
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              {type === 'success' ? (
                <path
                  d='M24.5 48C37.7562 48 48.5 37.2562 48.5 24C48.5 10.7438 37.7562 0 24.5 0C11.2438 0 0.5 10.7438 0.5 24C0.5 37.2562 11.2438 48 24.5 48ZM35.0938 19.5938L23.0938 31.5938C22.2125 32.475 20.7875 32.475 19.9156 31.5938L13.9156 25.5938C13.0344 24.7125 13.0344 23.2875 13.9156 22.4156C14.7969 21.5438 16.2219 21.5344 17.0938 22.4156L21.5 26.8219L31.9062 16.4062C32.7875 15.525 34.2125 15.525 35.0844 16.4062C35.9562 17.2875 35.9656 18.7125 35.0844 19.5844L35.0938 19.5938Z'
                  fill='#6FCE6D'
                />
              ) : (
                <>
                  <path
                    d='M30.4996 56.4001C45.0815 56.4001 56.8996 44.582 56.8996 30.0001C56.8996 15.4182 45.0815 3.6001 30.4996 3.6001C15.9177 3.6001 4.09961 15.4182 4.09961 30.0001C4.09961 44.582 15.9177 56.4001 30.4996 56.4001Z'
                    fill='#FF5454'
                  />
                  <path
                    d='M39.8136 23.4815C40.5867 22.7085 40.5867 21.453 39.8136 20.6799C39.0405 19.9068 37.7851 19.9068 37.012 20.6799L30.4996 27.1985L23.981 20.6861C23.208 19.913 21.9525 19.913 21.1794 20.6861C20.4063 21.4592 20.4063 22.7146 21.1794 23.4877L27.698 30.0001L21.1856 36.5187C20.4125 37.2917 20.4125 38.5472 21.1856 39.3203C21.9587 40.0934 23.2141 40.0934 23.9872 39.3203L30.4996 32.8017L37.0182 39.3141C37.7913 40.0872 39.0467 40.0872 39.8198 39.3141C40.5929 38.541 40.5929 37.2856 39.8198 36.5125L33.3012 30.0001L39.8136 23.4815Z'
                    fill='white'
                  />
                </>
              )}
            </svg>

            <p className='text-xl font-semibold mt-4 mb-1 text-center'>{wording}</p>
            {subwording && <p className='text-md text-gray-400 text-center'>{subwording}</p>}
          </div>
        </DialogContent>
        <div className='flex px-6 pb-6'>
          <Button variant='contained' className='w-full' onClick={onConfirm}>
            {buttonText}
          </Button>
        </div>
      </Dialog>
    )
  }
)

DialogResult.defaultProps = {
  type: 'success',
  wording: 'Success',
  buttonText: 'Close',
} as DialogResultProps

export {DialogResult}
