import {PageTitle} from 'src/app/components/Layout/header/HeaderTitle'
import {Button, Content, ContentHeader, Label, Radio, Stack} from 'src/app/components'
import {usePermissions} from 'src/app/hooks/permissions-hook'
import ScanTicketScreens from '../ScanTicketScreen'
import {
  HookFormProvider,
  InputDatePicker,
  InputRadioGroup,
  InputSelect,
  InputSelectAsync,
  InputTextField,
} from 'src/app/components/HookForm'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {getMall} from '../../directory/services/Mall.service'
import {useScanTicketHooks} from './ScanTicket.hook'
import {twMerge} from 'tailwind-merge'
import BarcodeScanner from '../components/BarcodeScanner/BarcodeScanner'
import {DialogLoading} from '../components/DialogLoading/DialogLoading'
import {DialogResult} from '../components/DialogResult/DialogResult'

function ScanTicketPage() {
  const {accessRedirect} = usePermissions()
  accessRedirect('gate_keeper.read')
  const state = useScanTicketHooks()

  return (
    <>
      <PageTitle description={ScanTicketScreens.SCAN_TICKET.TITLE}>
        {ScanTicketScreens.SCAN_TICKET.TITLE}
      </PageTitle>
      <Content className='min-h-0'>
        <ContentHeader title={ScanTicketScreens.SCAN_TICKET.TITLE} />
        <HookFormProvider form={state.form}>
          <Stack className='p-8'>
            <div className='grid lg:grid-cols-3'>
              <Label required={true}>Mall</Label>
              <InputSelectAsync
                placeholder='Select mall'
                fetchApi={getMall}
                payload={{statuses: ['ACTIVE']}}
                rule={{required: true}}
                form={state.form}
                name='directory'
                className='lg:col-span-2'
                onChangeValue={state.action.handleChangeMall}
                selectOption={(item) => ({
                  label: item.directory_name!,
                  value: item.id!,
                })}
              />
            </div>
            <div className='grid lg:grid-cols-3'>
              <Label required={true}>Event</Label>
              <InputSelect
                placeholder='Select Event'
                options={state.options.eventList.data?.content}
                className='lg:col-span-2'
                rule={{required: true}}
                form={state.form}
                name='event'
                selectOption={(_) => ({
                  label: _.reward_name,
                  value: _.id,
                })}
              />
            </div>
            <div className='grid lg:grid-cols-3'>
              <Label>Date</Label>
              <InputDatePicker
                placeholder='Select date'
                disabled
                name='date'
                form={state.form}
                className='lg:col-span-2'
              />
            </div>
            
            {state.options.sessions && state.options.sessions.length > 0 && (
              <div className='grid lg:grid-cols-3'>
                <Label required={true}>Select Session</Label>
                <div className='lg:col-span-2'>
                  <InputRadioGroup
                    childrenDirection='col'
                    childrenGap={1}
                    form={state.form}
                    rule={{required: true}}
                    name='session'
                  >
                    {state.options.sessions &&
                      state.options.sessions.map((session, index) => (
                        <label
                          id={String(index)}
                          onClick={() => state.action.handleSelectedSession(session)}
                          key={index}
                          className={twMerge(
                            'border border-primary rounded-lg p-3 cursor-pointer',
                            session.is_selected ? 'border-primary bg-[#F1EBFE]' : 'border-gray-100'
                          )}
                        >
                          <div className='flex flex-col lg:flex-row justify-between lg:items-center gap-2 lg:gap-4'>
                            <div className='flex items-center gap-4'>
                              <Radio
                                id={String(index)}
                                key={session.id}
                                containerClassname='m-0'
                                value={session.id}
                                required
                              />
                              <div
                                className={twMerge(
                                  'flex w-full lg:w-auto justify-between lg:justify-start items-center gap-4 font-thin text-sm',
                                  session.is_selected ? 'text-primary font-semibold' : 'text-gray-600'
                                )}
                              >
                                <span>{session.status}</span>
                                <span>{session.start_time} - {session.end_time}</span>
                              </div>
                            </div>
                            {session.is_selected && (
                              <div className='rounded-lg bg-slate-50 p-3 gap-4 flex justify-between items-center lg:w-2/4'>
                                <span className='text-sm'>Live Attandance</span>
                                <div className='flex items-center justify-between gap-4'>
                                  <div className='flex items-center gap-1'>
                                    <div className='w-3 h-3 bg-[#6FCE6D] rounded-full'></div>
                                    <span>{session.live_attendance}</span>
                                  </div>
                                  <Button onClick={() => state.action.handleRefetchGetSessionList} variant='outlined' size='xs'>
                                    Count
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </label>
                      ))}
                  </InputRadioGroup>
                </div>
              </div>
            )}
            <div className='grid lg:grid-cols-3 item'>
              <Label required={true}>ID Session</Label>
              <div className='grid grid-cols-[1fr_50px] gap-4 lg:gap-0 lg:grid-cols-1 col-span-2'>
                <InputTextField
                  placeholder='Enter ID Session'
                  rule={{required: true}}
                  name='member_ticket_code'
                  maxLength={10}
                />
                <div
                  onClick={state.action.openScanner}
                  className='grid place-items-center w-12 h-12 border border-primary rounded-lg lg:hidden cursor-pointer btn-scan'
                >
                  <GIcon icon='IconScan' />
                </div>
              </div>
            </div>
          </Stack>

          <div className='w-full flex items-center justify-end py-4 px-8 border-t'>
            <Button
              loading={state.isLoading}
              disabled={state.isLoading}
              onClick={state.action.handleSubmit}
            >
              Check Validation
            </Button>
          </div>
        </HookFormProvider>
      </Content>

      <BarcodeScanner
        onScan={(value) => {
          state.action.handleSetSessionId(value)
        }}
        isVisible={state.isScannerVisible}
        onClose={state.action.closeScanner}
      ></BarcodeScanner>

      <DialogResult
        type={state.resultDialogContent.type}
        wording={state.resultDialogContent.wording}
        subwording={state.resultDialogContent.subwording}
        onConfirm={state.action.closeResultDialog}
        ref={state.resultDialog}
      />
      <DialogLoading ref={state.loadingDialogRef} />
    </>
  )
}

export default ScanTicketPage
