import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { QueryModel } from "../models/api.types";
import { hasArray } from "../utils/array-utils";

interface UrlSearchParamsParams<T extends string> {
  defaultValues?: { [key in T]?: string }
}

export function useUrlSearchParams<T extends string>(
  { defaultValues }: UrlSearchParamsParams<T> = {}
): [{ [key in T]?: string }, (props: { [key in T]?: string }) => void] {

  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const params: any = {}
    urlParams.forEach((value: string, key: string) => {
      params[key] = value;
    });
    return params;
  }, [location.search]);

  useEffect(() => {
    if (!!defaultValues) {
      setSearchParams({
        ...defaultValues,
        ...searchParams
      });
    }
  }, [location.search]);

  const setSearchParams = useCallback((props: { [key: string]: string | undefined }) => {
    const urlParams = new URLSearchParams(location.search);
    const entriesParams = Object.entries(props);
    if (entriesParams.length > 0) {
      const shouldUpdate = entriesParams.reduce((prev, [key, value]) => {
        const searchValue = urlParams.get(key);
        if (!value) {
          urlParams.delete(key);
        } else {
          urlParams.set(key, value);
        }
        return (prev || value !== searchValue);
      }, false);
      if (shouldUpdate) {
        history.replace(`?${urlParams.toString()}`);
      }
    }
  }, [location.search]);

  return [
    searchParams,
    setSearchParams
  ];
}

const apiKeyParams = [
  'search',
  'search_by',
  'page',
  'sort',
  'order',
  'limit',
  'statuses',
  'status',
  'reward_id',
  'directory_ids[]',
  'start_date'
];

export function transformValueToArray(value?: string) {
  if (value?.includes('.')) {
    return value.split('.').filter((_) => !!_);
  }
  return value;
}

export function transformKey(searchParams: Record<string, any>, key: string) {
  const dotIndex = key.indexOf('.');
  if (!!searchParams[key] && dotIndex >= 0) {
    return key.substring(dotIndex + 1);
  }
  return key;
}

interface UseApiParamsParams<T extends string> {
  defaultValues?: { [key in T]?: string } & QueryModel,
  escapeTransformKeys?: string[],
  escapeTransformValues?: string[],
}

export function useApiParams<T extends string>(
  {
    escapeTransformKeys = [],
    escapeTransformValues = [],
    defaultValues
  }: UseApiParamsParams<T> = {}
): [{ [key in T]?: string } & QueryModel, (props: { [key in keyof QueryModel]?: string }) => void] {

  const initValues = { limit: 10, page: 1, ...defaultValues };
  const [searchParams, setSerachParams] = useUrlSearchParams({ defaultValues: initValues } as any);

  const apiParams = useMemo(() => {
    const returnParams = [...apiKeyParams, ...Object.keys(defaultValues || {})]
    return returnParams.reduce((stack, item) => {
      const value = searchParams[item as keyof QueryModel];
      if (value !== undefined) {
        if (['statuses', 'status'].includes(item)) {
          return ({ ...stack, statuses: [value] });
        }
       
        if (!apiKeyParams.includes(item)) {
          const _value = escapeTransformValues.includes(value) ? value : transformValueToArray(value);
          const key = escapeTransformKeys.includes(item) ? item : transformKey(searchParams, item);
          if (key === 'period' && hasArray(_value)) {
            return ({
              ...stack,
              start_date: _value[0],
              end_date: _value[1]
            });
          }
          return ({ ...stack, [key]: _value });
        }
        return ({ ...stack, [item]: value });
      }
      return (stack);
    }, {});
  }, [JSON.stringify(searchParams)]);
  return ([
    apiParams,
    setSerachParams
  ]);
}
