import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch, UseFormReturn } from 'react-hook-form';
import { Button, ActionButton, Select } from 'src/app/components';
import { InputTextField, InputSelect } from 'src/app/components/HookForm';

type TicketType = {
  ticket_type_id: string;
  session_ticket_type: string;
  price: number;
};

interface InputTicketFieldsProps {
  name: string;
  ticketOptions: { label: string; value: string; normal_price: number; special_price: number, disabled: boolean }[];
  isHoliday: boolean;
  ticketTypes: { ticket_type_id: string; session_ticket_type: string; price: number; disabled?: boolean | undefined; }[]
}

const InputTicketFields: React.FC<InputTicketFieldsProps> = ({ name, ticketOptions, isHoliday, ticketTypes }) => {
  const form = useFormContext(); // Get the full form object
  const { control, setValue, getValues } = form;
  const { fields, append, remove } = useFieldArray({ control, name });

  const selectedTickets = useWatch({ control, name });


  useEffect(() => {
    fields.forEach((field, index) => {
      const currentTicket = getValues(`${name}.${index}`);
      const selectedOption = ticketOptions.find(option => option.value === currentTicket.ticket_type_id);


      if (selectedOption) {
        const newPrice = isHoliday ? selectedOption.special_price : selectedOption.normal_price;
        // Only update the price if it is different
        if (currentTicket.price !== newPrice) {
          setValue(`${name}.${index}.price`, newPrice, { shouldDirty: true });
        }
      }
    });

  }, [isHoliday, fields, setValue, getValues, name, ticketOptions]);


  // Exclude already selected ticket types, except for the current row
  const getFilteredOptions = (currentIndex: number) => {
    const selectedTicketIds = selectedTickets
      .map((ticket: any) => ticket.ticket_type_id)
      .filter((id: string, index: number) => index !== currentIndex); // Exclude current row's selection

    return ticketOptions.filter(option => !selectedTicketIds.includes(option.value));
  };


  const getDisabled = (index: number) => {

    if (!ticketTypes || index >= ticketTypes.length || !ticketTypes[index]) {
     
      return false;
    }

    const currentTicket = getValues(`${name}.${index}`);
   
    return ticketTypes[index]?.disabled ?? false; 
  };



  const findValue = (id: string): any => {
    return fields.find((field) => field.id === id);
  };

  return (
    <div className="border border-gray-300 rounded-lg p-4 col-span-2">
      <table className="min-w-full">
        <thead>
          <tr className="text-left text-gray-700">
            <th className="p-2">Ticket Type</th>
            <th className="p-2">Price</th>
            <th className="p-2"></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="p-2">
                {getDisabled(index) ? (
                  // Render TextField if disabled
                  <InputTextField
                    placeholder="Select ticket type"
                    name={`${name}.${index}.session_ticket_type`}
                    value={findValue(field.id)?.session_ticket_type || ''}
                    disabled={true}
                    rule={{ required: true }}
                  />
                ) : (
                  // Render Select if not disabled
                  <Select
                    placeholder="Select ticket type"
                    options={getFilteredOptions(index)}
                    isMulti={false}
                    required={true}
                    name={`${name}.${index}.session_ticket_type`}
                    defaultInputValue={findValue(field.id)?.session_ticket_type || ''}
                    onChangeValue={(data: any) => {
                      const selectedOption = ticketOptions.find(
                        option => option.value === data?.value
                      );

                      if (selectedOption) {
                        const price = isHoliday ? selectedOption.special_price : selectedOption.normal_price;

                        // Set the selected values
                        setValue(`${name}.${index}.price`, price);
                        setValue(`${name}.${index}.session_ticket_type`, data?.label);
                        setValue(`${name}.${index}.ticket_type_id`, data?.value);
                       
                      }
                    }}
                  />
                )}

              </td>
              <td className="p-2">
                <InputTextField
                  type="currency"
                  placeholder="Rp"
                  prefix="Rp"
                  readOnly={true}
                  disabled={getDisabled(index)}
                  name={`${name}.${index}.price`}
                  rule={{ required: true }}
                />
              </td>
              <td className="p-2">
                <ActionButton disabled={getDisabled(index)} variant="delete" onClick={() => remove(index)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center justify-between p-4 mt-2 bg-gray-100">
        <span>Wanna sell more tickets?</span>
        <Button
          variant="outlined"
          onClick={() => append({ ticket_type_id: '', session_ticket_type: '', price: 0 })}
        >
          Add Ticket Type +
        </Button>
      </div>
    </div>
  );
};

export default InputTicketFields;
