import nl2br from "react-nl2br";

import {
  TransitionEvent,
  createElement,
  useEffect,
  useState
} from "react";
import {
  twMerge
} from "tailwind-merge";

import {
  InfoIcon,
  InfoPrimaryIcon,
  SuccessIcon,
  WarningIcon
} from "../../Icons/AlertIcons";
import {
  InfoSeverity,
  Theme
} from "./Info.theme";
import {
  InfoProps
} from "./info.types";

const icon = {
  info: InfoIcon,
  danger: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  gray: InfoIcon,
  primary: InfoPrimaryIcon,
};

export function Info({
  severity = 'info',
  className,
  startIcon,
  children,
  bordered,
  autoHide,
  autoHideDuration = 3000,
  onHide,
  ...props
}: InfoProps) {

  const [dismiss, setDismiss] = useState(autoHide);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (autoHide) {
      setTimeout(() => {
        setDismiss(true);
        onHide?.();
      }, autoHideDuration);
      setTimeout(() => {
        setDismiss(false);
      }, 100);
    }
  }, []);

  const handleTransitionEnd = (event: TransitionEvent<HTMLDivElement>) => {
    if (dismiss && event.propertyName === 'opacity') {
      setVisible(false);
      setDismiss(false);
    }
  };

  return (visible) ? (
    <div
      onTransitionEnd={handleTransitionEnd}
      className={twMerge(
        Theme.container,
        InfoSeverity[severity].container,
        bordered && InfoSeverity[severity].bordered,
        dismiss ? "opacity-0" : "opacity-100",
        className
      )}
      {...props}
    >
      {createElement(!startIcon ? icon[severity] : startIcon, {
        className: twMerge(Theme.icon, InfoSeverity[severity].icon)
      })}
      {typeof (children) === "string" ? nl2br(children) : children}
    </div>
  ) : null;
}

