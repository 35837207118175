import axios from "axios";

import {
  BaseResponse,
  Pagination,
  Response
} from "src/app/models/api.types";
import {
  RewardsModel,
  RewardWarningTransactionModel
} from "../models/Rewards.model";

export const URL_REWARDS = '/v1/cms/rewards';

// get rewards
export async function getRewards(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<RewardsModel[]>>>(URL_REWARDS, { params, signal });
}

// get rewards detail
export async function getRewardsDetail(id: string, signal?: AbortSignal, isTicket?: boolean) {
  return axios.get<BaseResponse<Response<RewardsModel>>>(`${URL_REWARDS}/${id}${isTicket ? '/tickets': ''}`, { signal });
}

// get rewards parking
export async function getRewardsParking({ id, ...params }: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<RewardsModel[]>>>(`${URL_REWARDS}/${id}/parking`, { params, signal });
}

// get rewards warning transaction
export async function getRewardWarningTransaction(id?: string, memberId?: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<RewardWarningTransactionModel>>>(`${URL_REWARDS}/${id}/members/${memberId}/warning-transaction`, { signal });
}

// save rewards
export function saveRewards(params: any) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<RewardsModel>>>(URL_REWARDS, params);
  }
  return axios.put<BaseResponse<Response<RewardsModel>>>(`${URL_REWARDS}/${params.id}`, params);
}

// save rewards Ticket
export function saveRewardsTicket(params: any) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<RewardsModel>>>(URL_REWARDS+'/tickets', params);
  }
  return axios.put<BaseResponse<Response<RewardsModel>>>(`${URL_REWARDS}/${params.id}/tickets`, params);
}

// delete rewards
export async function deleteRewards(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_REWARDS}/${id}`);
}

// validate bank rewards
export async function validateBankRewards(params: any) {
  return axios.post<BaseResponse<Response<[]>>>(`${URL_REWARDS}/validate-bank-rewards`, params);
}