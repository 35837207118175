import React from 'react';

interface TagProps {
  label: string;
  onDelete: () => void;
  disable: boolean
}

const Tag: React.FC<TagProps> = ({ label, onDelete, disable }) => {
  return (
    <div className="flex items-center justify-between bg-primary-100 border border-primary-300 text-primary-700 rounded-full py-1 px-2  shadow-md  transition-all">
      <span className="mr-3 font-base text-sm">{label}</span>
      <button
      disabled={disable}
        onClick={onDelete}
        className="text-gray-700 hover:text-purple-900 focus:outline-none text-sm"
      >
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50001 2.5L2.5 9.50001M2.50001 2.5L9.50002 9.50001" stroke="#CFBDFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </button>
    </div>
  );
};

export default Tag;
