export type BaseResponse<T> = {
  response_schema?: {
    response_code?: string
    response_message?: Translation
  }
  response_output?: T
}

export type Translation = {
  en?: string
  id?: string
}

export type PaginateModel = {
  page?: number
  total?: number
  size?: number
  rows_per_page?: number
  checked?: number
}

export type Pagination<T> = {
  list?: ResponseList<T>
}

export type ResponseList<T> = {
  content?: T
  pagination?: PaginateModel
}

export type Response<T> = {
  detail?: T
}

export type ResponseFieldError = {
  errors: {
    field?: string
    message?: Translation
  }[]
}

export type Constraint = {
  code?: string
  message?: string
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const StatusName: Record<string, string> = {
  [Status.ACTIVE]: 'Active',
  [Status.INACTIVE]: 'Inactive',
}

export type QueryModel = {
  page?: string | number
  limit?: string | number
  size?: string | number
  search?: string
  is_active?: string | boolean
  role_ids?: string | string[]
  order?: string | Order
  sort?: string
  start_date?: string
  end_date?: string
  action?: string
  statuses?: string[]
  reward_type?: string
  is_dropdown?: boolean
  payment_category_id?: string
}


export type QueryModelCustomerPointList = {
  statuses?: string[]
  sort?: string
  order?: string | Order
  limit?: string | number
  page?: string | number
  member_id?: string
  date?: string
}