const RewardsScreens = {
  REWARDS: {
    PATH: '/rewards',
    TITLE: 'Rewards',
    TITLE_ALT: 'Rewards',
  },
  LIST_REWARDS: {
    PATH: '/rewards/list/:path',
    TITLE: 'Rewards',
    TITLE_ALT: 'Rewards',
  },
  // LIST_VOUCHER: {
  //   PATH: '/rewards/list/:path',
  //   TITLE: 'Voucher',
  //   TITLE_ALT: 'Voucher',
  //   PERMISSION: ''
  // },
  ADD_REWARDS: {
    PATH: '/rewards/add/:path',
    TITLE: 'Add Rewards',
    TITLE_ALT: 'Add Rewards',
    PERMISSION: ''
  },
  DETAIL_REWARDS: {
    PATH: '/rewards/detail/:path/:id',
    TITLE: 'Detail Rewards',
    TITLE_ALT: 'Detail Rewards',
    PERMISSION: ''
  },
  EDIT_REWARDS: {
    PATH: '/rewards/edit/:path/:id',
    TITLE: 'Edit Rewards',
    TITLE_ALT: 'Edit Rewards',
    PERMISSION: ''
  },
  CHOOSE_FEATURED_REWARDS: {
    PATH: '/rewards/list/:path/choose-content',
    TITLE: 'Choose Rewards',
    TITLE_ALT: 'Choose Rewards',
    PERMISSION: ''
  },
  DETAIL_FEATURED_REWARDS: {
    PATH: '/rewards/detail/featured-rewards/:path/:id',
    TITLE: 'Detail Rewards',
    TITLE_ALT: 'Detail Rewards',
    PERMISSION: ''
  },
  ETICKET_EVENT: {
    PATH: '/rewards/list/:path/event',
    TITLE: 'Exhibition',
    TITLE_ALT: 'Exhibition',
    PERMISSION: ''
  },
  ETICKET_SESSION: {
    PATH: '/rewards/list/:path/session',
    TITLE: 'Exhibition',
    TITLE_ALT: 'Exhibition',
    PERMISSION: ''
  },
  ETICKET_DETAIL: {
    PATH: '/rewards/:form/exhibition/:path/:id',
    TITLE: 'Detail Rewards',
    TITLE_ALT: 'Detail Rewards',
    PERMISSION: ''
  },
}

export default RewardsScreens;
