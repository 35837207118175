import { SelectAsync } from "./SelectAsync.page";
import { GroupBase, Props } from "react-select";
import { OmitSelectProps } from "./Select.types";
import { SelectAsyncProps } from "./SelectAsync.types";
import { RewardsModel } from "src/app/modules/rewards/models/Rewards.model";
import { getRewards } from "src/app/modules/rewards/services/Rewards.service";

export function SelectRewards<
  IsMulti extends boolean = false,
  Group extends GroupBase<RewardsModel> = GroupBase<RewardsModel>
>(props: Omit<SelectAsyncProps<RewardsModel>, 'selectOption' | 'fetchApi'> & Omit<Props<RewardsModel, IsMulti, Group>, OmitSelectProps | 'options'>) {

  return (
    <SelectAsync
      fetchApi={getRewards}
      selectOption={(_) => ({
        label: _.reward_name!,
        value: _.id
      })}
      payload={{
        statuses: ["ACTIVE"],
        limit: 10
      }}
      {...props}
    />
  );
}
